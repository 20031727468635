var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-row" },
    [
      _c(
        "v-container",
        { staticClass: "category-block-header" },
        [_c("category-title", { attrs: { category: _vm.category } })],
        1
      ),
      _vm._l(_vm.category.categoryBlocks, function(config, idx) {
        return _c("category-block", {
          key: config.categoryBlockId,
          class: `category-block category-block-${idx}`,
          attrs: { config: config }
        })
      }),
      _c("ebsn-meta", {
        staticClass: "category-block-footer",
        attrs: {
          target: _vm.category,
          path: "category_info.FOOTER_DESCRIPTION",
          tag: "div"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }